import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { ErrorHandler, ERROR_CATCH_TYPE } from "@classdojo/web/pods/errorHandling";
import { IframeError } from "@classdojo/web/pods/realtime/adapters/iframe";
import { PubnubError } from "@classdojo/web/pods/realtime/adapters/pubnub";
import { UserActionsHistory } from "@classdojo/web/pods/userActionsHistory";
import { isApiDownStatus } from "@web-monorepo/infra/responseHandlers";
import { Response } from "superagent";
import { metrics } from "app/utils/metrics";
import { captureException } from "@web-monorepo/telemetry";
import { toast } from "react-toastify";
import ToastBanner from "@classdojo/web/nessie/components/ToastBanner";
import { ErrorBannerValues } from "@classdojo/web/pods/errorHandling/errorBanner";

const isIgnorableBadRequest = (response?: Response) => {
  if (!response) return false;
  return (
    response.status === 400 &&
    ["Invalid id", "Invalid targetId", "Invalid classId", "ChannelId was invalid"].includes(
      response.body?.error?.detail,
    )
  );
};

export const createWebErrorHandler = (userActionsHistory: UserActionsHistory) => {
  return new ErrorHandler({
    userActionsHistory,
    // eslint-disable-next-line complexity
    onError: (error, context, errorHandlerUtils) => {
      // These errors happen when request where blocked by a filtering service. Lets just show an error banner and ignore it.
      if (
        (error.message.includes("Error returned in API call: 307") && error.message.includes("Request Blocked")) ||
        error.message.includes("Empty response from filtering service")
      ) {
        errorHandlerUtils.showErrorBanner(ErrorBannerValues.requestBlocked);
        return;
      }

      if (
        error.message.includes("Failed to fetch dynamically imported module") ||
        error.message.includes("Importing a module script failed")
      ) {
        errorHandlerUtils.showErrorBanner(
          ErrorBannerValues.moduleLoadError,
          window.location.pathname + window.location.hash,
        );
        return;
      }

      const response = isApiError(error) ? error.response : undefined;

      // Send 400 bad request errors as warning (via logRequestError) and add a metric with type `frontend.badRequest`
      // Redirecting and ignoring the error here because the user probably entered a bad url, not an actionable error.
      if (isIgnorableBadRequest(response)) {
        errorHandlerUtils.logRequestErrorWithContext("badRequest", error);
        errorHandlerUtils.showErrorBanner(ErrorBannerValues.unknown, "/#/launchpad");
        return;
      }

      // Logged out errors are sent as warning (via logRequestError) and add a metric.
      // Redirecting and ignoring, not an actionable error.
      if (response && [401, 403].includes(response.status)) {
        // Log authentication/authorization failure as a warning in logs, and add a metric so alarms can be
        // triggered.
        errorHandlerUtils.logRequestErrorWithContext(response.status === 401 ? "auth.401" : "auth.403", error);

        // Sends the user to launchpad if user not in launchpad already, this improves the user experience
        // because avoids a redirection.
        const destination =
          response.status === 401 || window.location.href.includes("/launchpad") ? "/login" : "/launchpad";
        errorHandlerUtils.showErrorBanner(ErrorBannerValues.unknown, `/#${destination}`);

        return;
      }

      // Not found errors are sent as warnings (via logRequestError) and add a metric.
      // The app already knows how to handle a page not found error
      if (response && response.status === 404) {
        // Log not found errors as a warning in logs, and add a metric so alarms can be
        // triggered.
        errorHandlerUtils.logRequestErrorWithContext("request.404", error);
        errorHandlerUtils.showErrorBanner(ErrorBannerValues.unknown);
        return;
      }

      if (response && response.status === 429) {
        // Capture rate limit errors in sentry
        captureException(error, { data: context });

        errorHandlerUtils.logRequestErrorWithContext("rateLimit", error);
        errorHandlerUtils.showErrorBanner(ErrorBannerValues.rateLimit);
        return;
      }

      if (response?.status === 400 && response?.body?.error?.detail === "None of those students are in that class") {
        errorHandlerUtils.showErrorBanner(ErrorBannerValues.studentsNotInClass);
        return;
      }

      // API down error
      if (response && isApiDownStatus(response.status)) {
        errorHandlerUtils.logRequestErrorWithContext("down", error);
        const statusCode = response.statusCode;
        const errorMessage = response.body?.error?.message || "";
        const isLoadSheddingError = errorMessage.toLowerCase().includes("load shedding");

        if (statusCode === 503 && isLoadSheddingError) {
          errorHandlerUtils.showErrorBanner(ErrorBannerValues.apiDownLoadShedding);
        } else {
          errorHandlerUtils.showErrorBanner(ErrorBannerValues.apiDown);
        }
        return;
      }

      if (context.catchType === ERROR_CATCH_TYPE.REACT_ERROR_BOUNDARY) {
        const errorBoundaryError = new Error(error.message);
        errorBoundaryError.name = `React ErrorBoundary ${error.name}`;
        errorBoundaryError.stack = context.componentStack;
        errorBoundaryError.cause = error;
        captureException(errorBoundaryError, { data: context });
      } else {
        captureException(error, { data: context });
      }

      if (error.message.includes("Failed to fetch dynamically imported module")) {
        toast(
          <ToastBanner
            text={autoTranslate(
              "There has been an error loading the page. This is likely caused by an unstable internet connection. Please reload and try again.",
            )}
          />,
        );
        return;
      }

      switch (context.catchType) {
        case ERROR_CATCH_TYPE.REACT_ERROR_BOUNDARY: {
          errorHandlerUtils.logExceptionWithContext(error);
          errorHandlerUtils.showErrorBanner(ErrorBannerValues.unknown);
          break;
        }
        case ERROR_CATCH_TYPE.UNHANDLED_REJECTION: {
          if (error instanceof PubnubError) {
            errorHandlerUtils.showErrorBanner(ErrorBannerValues.pubnub);
            break;
          }
          if (error instanceof IframeError) {
            // send metric
            metrics.increment("iframe.handshake.timeout", { targetOrigin: error.targetOrigin ?? "empty" });

            // TODO: We are disabling this temporarly until we identify why this is happening so often when simply navigating
            // between classes in Teach. Right it's showing a banner to any user that navigates between classes.
            // errorHandlerUtils.showErrorBanner("unknown");
            break;
          }
          errorHandlerUtils.logExceptionWithContext(error);

          break;
        }
        case ERROR_CATCH_TYPE.MODULE_LOAD_ERROR: {
          errorHandlerUtils.showErrorBanner(
            ErrorBannerValues.moduleLoadError,
            window.location.pathname + window.location.hash,
          );
          break;
        }
        // Handles REDUX and SAGAS errors:
        default: {
          errorHandlerUtils.logExceptionWithContext(error);
          errorHandlerUtils.showErrorBanner(ErrorBannerValues.unknown);
        }
      }
    },
  });
};

const isApiError = (error: Error): error is Error & { response: Response } => "response" in error;
