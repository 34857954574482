import { useState } from "react";
import callApi from "@web-monorepo/infra/callApi";
import { DDSButton, DDSTextField, DDSBodyText } from "@web-monorepo/dds";

const Search = ({
  setConfigKey,
  setData,
  setError,
}: {
  setConfigKey: (configKey: string) => void;
  setData: (data: string) => void;
  setError: (error: string) => void;
}) => {
  const [search, setSearch] = useState("");
  const searchEntityConfigValueStore = async () => {
    try {
      const data = await callApi({
        method: "GET",
        path: `/api/entityConfigValueStore/${search}`,
      });
      setConfigKey(search);
      setData(JSON.stringify(data.body));
      setError("");
    } catch (error: any) {
      if (error.response?.statusCode === 404) {
        setError(`unknown entityConfigValueStore item: ${search}`);
      } else {
        setError("An unexpected error occurred");
        throw error;
      }
    }
  };

  return (
    <div sx={{ paddingBottom: "20px" }}>
      <DDSTextField
        data-name="devTools.entityConfigValueStoreSearchInput"
        label="Search for the current value of an entityConfigValueStore item"
        onChange={setSearch}
        size="medium"
        value={search}
        variant="default"
      />
      <DDSButton data-name="devTools.entityConfigValueStoreSearch" onClick={searchEntityConfigValueStore}>
        Search
      </DDSButton>
    </div>
  );
};

const Update = ({
  configKey,
  setData,
  setError,
}: {
  configKey: string;
  setData: (data: string) => void;
  setError: (error: string) => void;
}) => {
  const [body, setBody] = useState("");

  const updateEntityConfigValueStore = async () => {
    try {
      const data = await callApi({
        method: "PUT",
        path: `/api/entityConfigValueStore/${configKey}`,
        body: JSON.parse(body) as Record<string, unknown>,
      });
      if (data.statusCode === 200) {
        setData(body);
        setError("");
      } else {
        setError(JSON.stringify(data.body));
      }
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (error: any) {
      setError(error?.response?.body?.error?.detail || "An unknown error occurred, check network tab for more details");
    }
  };

  return (
    <div sx={{ paddingTop: "20px" }}>
      <DDSTextField
        data-name="devTools.entityConfigValueStoreUpdateInput"
        label={`Set a new value for ${configKey}`}
        onChange={setBody}
        size="medium"
        value={body}
        variant="default"
        placeholder={`Enter json like {"readAt": "date"}`}
      />

      <DDSButton data-name="devTools.entityConfigValueStoreUpdate" onClick={updateEntityConfigValueStore}>
        Update
      </DDSButton>
    </div>
  );
};

const Clear = ({
  configKey,
  setData,
  setError,
}: {
  configKey: string;
  setData: (data: string) => void;
  setError: (error: string) => void;
}) => {
  const clearEntityConfigValueStore = async () => {
    const data = await callApi({
      method: "DELETE",
      path: `/api/entityConfigValueStore/${configKey}`,
    });
    if (data.statusCode === 200) {
      setData("{}");
      setError("");
    } else {
      setError(JSON.stringify(data.body));
    }
  };

  return (
    <div sx={{ paddingTop: "20px" }}>
      <DDSButton data-name="devTools.entityConfigValueStoreDelete" onClick={clearEntityConfigValueStore}>
        Clear {configKey}
      </DDSButton>
    </div>
  );
};

export const EntityConfigValueStoreContainer = () => {
  const [configKey, setConfigKey] = useState("");
  const [data, setData] = useState("");
  const [error, setError] = useState("");

  return (
    <div sx={{ padding: "10px" }}>
      <Search setConfigKey={setConfigKey} setData={setData} setError={setError} />

      {error ? (
        <DDSBodyText>{error}</DDSBodyText>
      ) : (
        <>
          <div>
            <DDSBodyText disable-tag-translation>Config key {configKey} current value is:</DDSBodyText>
          </div>
          <DDSBodyText> {`${data}`}</DDSBodyText>
        </>
      )}

      {configKey && <Clear configKey={configKey} setData={setData} setError={setError} />}

      {configKey && <Update configKey={configKey} setData={setData} setError={setError} />}
    </div>
  );
};
