import env from "../utils/env";

const _console = {
  // eslint-disable-next-line no-console
  warn: console.warn.bind(console),
};

if (!env.isProduction) {
  // eslint-disable-next-line no-console
  console.warn = (message, ...args) => {
    const isReactLifeCycleWarning = /To rename all deprecated lifecycles to their new names, you can run/.test(message);
    if (isReactLifeCycleWarning) {
      return;
    }
    _console.warn(message, ...args);
  };
}
