import { DDSHeadlineText } from "@web-monorepo/dds";

export function ModuleError({ error }: { error: any }) {
  if (Config.nodeEnv === "production") {
    return (
      <DDSHeadlineText i18n-context="Error page if there's a problem loading a piece of the application">
        Loading Error
      </DDSHeadlineText>
    );
  }

  if (error instanceof Error) {
    return (
      <>
        <DDSHeadlineText i18n-context="Error page if there's a problem loading a piece of the application">
          Loading Error
        </DDSHeadlineText>
        <pre>{error.stack}</pre>
      </>
    );
  }
  return (
    <DDSHeadlineText i18n-context="Error page if there's a problem loading a piece of the application">
      Unknown module error
    </DDSHeadlineText>
  );
}
